import React, { useState, useEffect, useContext } from 'react';
import OnceFooter from "./../common/Footer/OnceFooter";
import { useParams } from 'react-router-dom';
import api from './../../utils/api';
import { AppContext } from "./../context/ContextProvider";
import { Modal, Button, Row, Col, Form, Popover, OverlayTrigger } from 'react-bootstrap';
import { numberFormat, inputKeyPressOnlyNumber, optionValue } from './../../utils/functions';
import { toast } from 'react-toastify';
import moment from "moment";
import 'moment/locale/th'
import _ from "lodash";
import { Lightbox } from "react-modal-image";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export default function CampingBooking() {
  const navigate = useNavigate();
  const { hotel_id } = useParams();
  const { handleSetCurrentHotel, currentHotel } = useContext(AppContext);

  const [currentStartDate, setCurrentStartDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const fetchHotel = () => {
    api.request(`hotels/${hotel_id}`, (data) => {
      if (!!data && !!data.allow_walkin) {
        handleSetCurrentHotel(data)
      }
    }, {})
  }

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState(null);

  const [addons, setAddons] = useState([]);
  const [addonOptions, setAddonsOptions] = useState([]);

  const [campingType, setCampingType] = useState([]);
  const [walkinSummary, setWalkinSummary] = useState([]);

  const dayQty = moment(endDate).diff(moment(startDate), 'days') || 0

  const [form, setForm] = useState({
    payment_type: 'full',
    payment_state: 'waiting',
    state: 'payment',
    booking_date: new Date(),
    hotel_id: hotel_id,
    room_id: null,
    total_price: 0,
    booking_note: '',
    slip_file: null
  });

  let discountPrice = 0

  if (discount && discount?.coupon) {
    const c = discount?.coupon

    if (c.discount_type === 'static') {
      discountPrice = Number(c.discount_price) || 0
    } else {
      discountPrice = (Number(form?.total_price || 0) || 0) * ((Number(c.discount_price) || 0) / 100)
    }

    if (discountPrice > Number(form.total_price)) {
      discountPrice = Number(form.total_price)
    }
  }

  const sumAddons = _.sumBy(addons || [], (x) => { return Number(x.total_price || 0) }) || 0;
  const payAmount = Number(form.total_price) - discountPrice + sumAddons

  const fetchGuestSumByDate = (date, hotelId) => {
    if (!date || !hotelId) return
    api.request('bookings/walkin-guest-summary', (data) => {
      setWalkinSummary(data || [])
    }, {
      hotel_id: hotelId,
      start_date: moment(date).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment(date).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    })
  }

  const fetchHotelAddonsItem = (hId) => {
    api.request(`hotel-addons/all`, (data) => {
      setAddonsOptions(data?.map((item) => {
        return {
          label: item.name_th,
          value: item.id,
          _price: item.default_price || 0
        }
      }) || [])
    }, { hotel_id: hId, enabled: true, is_non_inventory: true })
  }

  const fetchCampingTypes = (hId) => {
    api.request(`camp-types/all`, (res) => {
      setCampingType(res?.map((item) => {
        return {
          label: item.name_th,
          value: item.id,
          _price: item.default_price || 0
        }
      }) || [])
    }, {
      enabled: true,
      hotel_id: hId
    })
  }

  const validateAddons = () => {
    if (!addons.length) {
      return true
    }

    let l = (addons.filter((x) => !!x.addons_id)) || []

    return l.length === addons.length
  }

  useEffect(() => {

    const g_qty = form.guest_qty || 0
    const c_qty = form.guest_child_qty || 0

    const pricePerDay = Number(currentHotel?.walk_in_total_price || 0)
    const childPricePerDay = Number(currentHotel?.walk_in_child_total_price || 0)

    const p = (((g_qty * pricePerDay) + (c_qty * childPricePerDay)) * dayQty)
    setForm({ ...form, total_price: p })
  }, [form.guest_qty, form.guest_child_qty, dayQty])

  useEffect(() => {
    if (!hotel_id) {
      return
    }
    fetchHotel(hotel_id)
    fetchHotelAddonsItem(hotel_id)
    fetchCampingTypes(hotel_id)
    fetchGuestSumByDate((currentStartDate || new Date()), hotel_id)
  }, [hotel_id])

  useEffect(() => {
    if (!!hotel_id && !!currentStartDate) {
      fetchGuestSumByDate((currentStartDate), hotel_id)
    }
  }, [currentStartDate])

  function loopDateFromStartDateToEndDate(start, end) {
    const diff = moment(end).diff(moment(start), 'days')
    if (diff <= 0) return []

    const arr = []

    for (let i = 0; i < diff; i++) {
      const d = moment(start).add(i, 'days').toDate()
      arr.push(d)
    }

    return arr
  }

  const checkGuestQty = (date) => {
    const limit = Number(currentHotel?.walkin_limit || 0)
    const g = walkinSummary.find((x) => moment(x.date).isSame(moment(date), 'day'))
    return !!g ? (limit - Number(g?.guest_summary || 0)) : limit
  }

  function checkRoomNotAvailable(start_date, end_date) {
    const dates = loopDateFromStartDateToEndDate(start_date, end_date) || []

    return dates.some((d) => {
      return checkGuestQty(d) <= 0
    })
  }

  function checkGuestQtyWithStartDateEndDate(start_date, end_date, g_qty, c_qty) {
    if (!start_date || !end_date) return true

    const dates = loopDateFromStartDateToEndDate(start_date, end_date) || []

    return dates.every((d) => {
      return checkGuestQty(d) >= ((Number(g_qty) || 0) + (Number(c_qty) || 0))
    })
  }

  function tileClassName({ date, view }) {
    if (view !== 'month') return null;
    const classes = ['booking-calendar-date'];
    const isPartDate = moment(date).isBefore(moment(), 'day')

    if (!isPartDate) {

    } else {
      classes.push('part-date');
    }

    return classes.join(' ');
  }

  const popoverBottom = (
    <Popover id="popover-positioned-bottom" title="Popover bottom">
      <div className="p-2">
        <span className="text-success">เลือกวันที่เข้าพัก โดยกดวันเช็คอิน และวันเช็คเอาท์บนปฏิทิน </span><span className="text-black">(วันที่ถูกเลือกจะแสดงแถบสีดำ)</span>
      </div>
    </Popover>
  );

  return (
    <>
      {(!currentHotel || !currentHotel?.allow_walkin) ?
        <div className="text-center py-5 px-2 px-md-5">
          <h4>ขออภัย การจองพื้นที่กางเต็นท์/Car Camp ไม่สามารถใช้งานได้ในขณะนี้</h4>
        </div>
        :
        <div className="text-center py-5 px-2 px-md-5">
          <h4>เช่าพื้นที่กางเต็นท์/Car Camp/Walk - In</h4>
          <Row>
            <Col xs={12} md={6}>
              <div className={`mt-2 mb-4`}>
                <div className="mb-2 d-flex title-btn">
                  <span onClick={() => setCurrentStartDate(new Date())} className="text-black border rounded py-1 px-2"><i className="fal fa-calendar"></i> TODAY</span>
                  <OverlayTrigger trigger="click" placement="bottom" overlay={popoverBottom} rootClose>
                    <span className="ms-3" style={{ backgroundColor: '#ececec', color: '#000000', padding: '5px 10px' }}>กรุณาเลือกวันที่ต้องการเข้าพักบนปฏิทิน
                      <i className="fas fa-info-circle ps-2" style={{ fontSize: 18 }}></i>
                    </span>
                  </OverlayTrigger>
                </div>
                <Calendar
                  selectRange={true}
                  showDoubleView={false}
                  minDate={new Date()}
                  calendarType={'hebrew'}
                  locale={'th-TH'}
                  activeStartDate={currentStartDate}
                  showNavigation={true}
                  tileClassName={tileClassName}
                  tileContent={({ activeStartDate, date, view }) => {
                    const g = checkGuestQty(date)

                    return (view === 'month') ?
                      (
                        <div className="price-container">
                          {g > 0 && <p className={`booking-calendar-price text-success`}>
                            <i className="fal fa-user me-1"></i>
                            {g}</p>}
                          {g <= 0 && <p className={`booking-calendar-price text-danger`}>เต็ม</p>}
                        </div>
                      )
                      : null
                  }}
                  value={[startDate, endDate]}
                  onChange={(e) => {
                    if (Array.isArray(e) && e.length === 2) {
                      if (checkRoomNotAvailable(e[0], e[1])) {
                        setStartDate(null)
                        setEndDate(null)
                        return
                      }

                      if (!checkGuestQtyWithStartDateEndDate(e[0], e[1], form.guest_qty, form.guest_child_qty)) {
                        toast.warn('จำนวนผู้เข้าพักเกินจำนวนที่สามารถจองได้')
                        return
                      }

                      setStartDate(e[0])
                      setEndDate(e[1])
                    }
                  }}
                  onActiveStartDateChange={(s) => {
                    if (s?.activeStartDate) {
                      setCurrentStartDate(s.activeStartDate)
                    }
                  }}
                />
              </div>
            </Col>
            <Col xs={12} md={6} className="text-start mt-0 mt-md-5 booking-form-input">
              <div>
                <i className="fal fa-angle-right"></i> ผู้ใหญ่ ท่านละ <span className="text-black">{numberFormat(currentHotel.walk_in_total_price, true)} บาท</span>
              </div>
              <div>
                <i className="fal fa-angle-right"></i> เด็ก (อายุ 6 - 12 ปี) ท่านละ <span className="text-black">{numberFormat(currentHotel.walk_in_child_total_price, true)} บาท</span>
              </div>
              <div>
                <i className="fal fa-angle-right"></i> <span>เด็ก (ต่ำกว่า 6 ปี) ไม่คิดค่าบริการ</span>
              </div>
              <div className="d-flex mt-2">
                <strong className="text-black">วันที่เข้าพัก : </strong> {(!!startDate && !!endDate) && <span className="ms-2">{moment(startDate).format("DD/MM/YYYY")} - {moment(endDate).format("DD/MM/YYYY")} ({dayQty} คืน)</span>} {(!startDate || !endDate) && <span className="ms-2 text-warning">กรุณาเลือกวันที่เข้าพัก</span>}
              </div>
              <strong className="text-black mt-3 mb-1">จำนวนผู้เข้าพัก</strong>
              <Row>
                <Col xs={4}>
                  <label>ผู้ใหญ่</label>
                  <input
                    type="number"
                    name="guest_qty"
                    placeholder="ผู้ใหญ่"
                    min={1}
                    onKeyPress={inputKeyPressOnlyNumber} value={form.guest_qty || ""}
                    onChange={(e) => {
                      if (checkGuestQtyWithStartDateEndDate(startDate, endDate, e.target.value, form.guest_child_qty)) {
                        setForm({ ...form, guest_qty: e.target.value })
                      } else {
                        toast.warn('จำนวนผู้เข้าพักเกินจำนวนที่สามารถจองได้')
                      }
                    }}
                    required
                  />
                </Col>
                <Col xs={4}>
                  <label>เด็ก 6 - 12 ปี</label>
                  <input
                    type="number"
                    name="guest_child_qty"
                    placeholder="เด็ก 6 - 12 ปี"
                    onKeyPress={inputKeyPressOnlyNumber} value={form.guest_child_qty || ""}
                    onChange={(e) => {
                      if (checkGuestQtyWithStartDateEndDate(startDate, endDate, form.guest_qty, e.target.value)) {
                        setForm({ ...form, guest_child_qty: e.target.value })
                      } else {
                        toast.warn('จำนวนผู้เข้าพักเกินจำนวนที่สามารถจองได้')
                      }
                    }}
                  />
                </Col>
                <Col xs={4}>
                  <label>เด็กต่ำกว่า 6 ปี</label>
                  <input
                    type="number"
                    name="guest_baby_qty"
                    placeholder="เด็กต่ำกว่า 6 ปี"
                    onKeyPress={inputKeyPressOnlyNumber} value={form.guest_baby_qty || ""}
                    onChange={(e) => setForm({ ...form, guest_baby_qty: e.target.value })}
                  />
                </Col>
              </Row>
              <div className="mt-3"> <strong className="text-black">ราคารวม : </strong><span className="ms-2 text-success">{numberFormat(form.total_price)} บาท</span></div>
              <div className="mt-2">
                <strong className="text-warning">ส่วนลด/คูปอง (ถ้ามี)</strong>

                {!discount && <Row className="mb-1">
                  <Col xs={8}>
                    <input
                      type="text"
                      name="coupon_code"
                      placeholder="ระบุโค้ดส่วนลด"
                      value={couponCode || ""}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                  </Col>
                  <Col xs={4}>
                    <Button disabled={!couponCode?.length} className="btn btn-sm btn-success" onClick={() => {
                      setDiscount(null)

                      api.request(`coupons/items/${couponCode}`, (data, suc) => {
                        if (!suc) {
                          return
                        }

                        if (data.error) {
                          toast.warn(data.message)
                          return
                        }
                        setDiscount(data.coupon)
                      }, {})
                    }}>
                      ใช้คูปอง
                    </Button>
                  </Col>
                </Row>}
                {!!discount && <Row className="mb-1">
                  <Col xs={8}>
                    <strong className="text-success"> ส่วนลดที่ได้รับ : {numberFormat(discountPrice)} บาท</strong>
                  </Col>
                  <Col xs={4}>
                    <Button className="btn btn-sm btn-danger" onClick={() => {
                      setCouponCode('')
                      setDiscount(null)
                    }}>
                      ยกเลิกคูปอง
                    </Button>
                  </Col>
                </Row>}
              </div>
            </Col>
          </Row>
          <div>
            <div className="booking-form-input my-3 text-start">
              <Row className="mb-1">
                <Col xs={6} md={3}>
                  <div className="top-label" style={{ flex: 1 }}>
                    <Select
                      options={campingType}
                      onChange={(option) => {
                        setForm({ ...form, camp_type_id: option.value })
                      }}
                      className={`flex-fill mt-2  ${!!form?.camp_type_id ? '' : 'camp-type-select-warning'}`}
                      placeholder="*ประเภทการเข้าพัก"
                      value={optionValue(form?.camp_type_id, campingType)}
                      classNamePrefix="react-select-custom"
                      id={`camp_type_select`}
                      required
                    />
                  </div>
                </Col>
                <Col xs={6} md={3}>
                  <input
                    className="mt-2"
                    type="number"
                    name="tent_qty"
                    placeholder="จำนวนเต็นท์/รถ"
                    onKeyPress={inputKeyPressOnlyNumber} value={form.tent_qty || ""}
                    onChange={(e) => setForm({ ...form, tent_qty: e.target.value })}
                  />
                </Col>
                <Col xs={6} md={3}>
                  <input
                    className="mt-2"
                    type="text"
                    name="booking_contact_name"
                    placeholder="ระบุชื่อผู้ติดต่อ"
                    value={form.contact_name || ""}
                    onChange={(e) => setForm({ ...form, contact_name: e.target.value })}
                    required
                  />
                </Col>
                <Col xs={6} md={3}>
                  <input
                    className="mt-2"
                    type="text"
                    name="booking_tel_1"
                    placeholder="เบอร์ติดต่อ (กรุณาระบุเฉพาะตัวเลข)"
                    onKeyPress={inputKeyPressOnlyNumber} value={form.contact_tel_1 || ""}
                    onChange={(e) => {
                      if (e.nativeEvent?.inputType === "insertFromPaste" && e.target.value) {
                        const t = e.target.value.replace(/\D/g, '')
                        setForm({ ...form, contact_tel_1: t })
                      } else {
                        setForm({ ...form, contact_tel_1: e.target.value })
                      }
                    }}
                    required
                  />
                </Col>
                <Col xs={6} md={3}>
                  <input
                    className="mt-2"
                    type="text"
                    name="contact_line_id"
                    placeholder="ไลน์ไอดี/LINE ID (ถ้ามี)"
                    value={form.contact_line_id || ""}
                    onChange={(e) => setForm({ ...form, contact_line_id: e.target.value })}
                  />
                </Col>
                <Col xs={6} md={3}>
                  <input
                    className="mt-2"
                    type="text"
                    name="contact_email"
                    placeholder="อีเมล (ถ้ามี)"
                    value={form.contact_email || ""}
                    onChange={(e) => setForm({ ...form, contact_email: e.target.value })}
                  />
                </Col>
                <Col xs={6} md={3}>
                  <input
                    className="mt-2"
                    type="text"
                    name="customer_car_model"
                    placeholder="ยี่ห้อรถ"
                    value={form.customer_car_model || ""}
                    onChange={(e) => setForm({ ...form, customer_car_model: e.target.value })}
                  />
                </Col>
                <Col xs={6} md={3}>
                  <input
                    className="mt-2"
                    type="text"
                    name="customer_car_license_plate"
                    placeholder="ทะเบียนรถ"
                    value={form.customer_car_license_plate || ""}
                    onChange={(e) => setForm({ ...form, customer_car_license_plate: e.target.value })}
                  />
                </Col>
              </Row>
              <textarea
                className="mt-2"
                type="text"
                name="booking_note"
                placeholder="ความต้องการเพิ่มเติม"
                value={form.booking_note || ""}
                onChange={(e) => setForm({ ...form, booking_note: e.target.value })}
              />
            </div>
            {addonOptions && addonOptions.length > 0 && <div className="mt-2 text-start">
              <strong className="text-warning">รายการอุปกรณ์เสริม(ถ้ามี)</strong>
              <div className="mb-3">
                {addons.map((ad, i) => {
                  return (
                    <div className="mt-3" key={`addons-list-${i}`}>
                      <div className="d-flex mb-3 ">
                        <div className="top-label" style={{ flex: 1 }}>
                          <Form.Label htmlFor={`addons_name_${i}`}>อุปกรณ์เสริม{i + 1}</Form.Label>
                          <Select
                            options={addonOptions}
                            onChange={(option) => {
                              let ads = [...addons]
                              ad.addons_id = option.value
                              ad.unit_price = option._price
                              ad.total_price = ((Number(option._price) * Number(ad.qty)) || 0)
                              ads[i] = ad
                              setAddons(ads)
                            }}
                            className={'flex-fill'}
                            placeholder="เลือกอุปกรณ์เสริม"
                            value={optionValue(ad.addons_id, addonOptions)}
                            classNamePrefix="react-select"
                            id={`addons_name_${i}`}
                          />
                        </div>
                        <div className="top-label ms-2" style={{ width: 70 }}>
                          <Form.Label htmlFor={`addons_qty_${i}`}>จำนวน</Form.Label>

                          <Form.Control id={`addons_qty_${i}`} type="number" min="1" onKeyPress={inputKeyPressOnlyNumber} value={ad.qty || ""} onChange={(e) => {
                            let ads = [...addons]
                            ad.qty = e.target.value
                            ad.total_price = Number(e.target.value) * Number(ad.unit_price)
                            ads[i] = ad
                            setAddons(ads)
                          }} />
                        </div>
                        <div className="top-label ms-2" style={{ width: 100 }}>
                          <Form.Label htmlFor={`addons_price_${i}`}>ราคา</Form.Label>
                          <Form.Control id={`addons_price_${i}`} type="text" value={numberFormat(ad.total_price) || ""} disabled />
                        </div>
                        <Button variant="outline-danger" disabled={submitting} className="btn-icon btn-icon-only ms-2" onClick={() => {
                          let ads = [...addons]
                          ads.splice(i, 1)
                          setAddons(ads)
                        }}>
                          <i className="fa fa-trash" />
                        </Button>
                      </div>
                    </div>
                  )
                })}
                {addons && addons.length > 0 && <p className="text-primary">รวมค่าบริการอุปกรณ์เสริม {numberFormat(sumAddons || 0)}</p>}
                <Button variant="light" disabled={submitting} className="btn-icon btn-sm" onClick={() => {
                  let ads = [...addons]
                  ads.push({ name: '', qty: 1, total_price: 0, unit_price: 0 })
                  setAddons(ads)
                }}>
                  <i className="fa fa-plus me-2" />
                  เพิ่มอุปกรณ์เสริม
                </Button>
              </div>
            </div>}
            <hr />
            <div className="text-start">
              {!!currentHotel.payment_info_th && <div>
                <p className="mb-2 text-black">ช่องทางการชำระเงิน</p>
                <div>
                  <div dangerouslySetInnerHTML={{ __html: currentHotel.payment_info_th }} />
                </div>
              </div>}
              <div>
                <h5><strong>จำนวนเงินที่ต้องชำระ : </strong><span className="ms-2 text-success">{numberFormat(payAmount)} บาท</span></h5>
              </div>
              <div className="d-flex mb-2 align-items-end mt-3">
                <div>
                  <label htmlFor={`form_file_slip`} className="form-label">แนบหลักฐานการชำระเงิน</label>
                  <input accept="image/*" className="form-control" id={`form_file_slip`} type="file" onChange={(e) => {
                    if (e?.target?.files && e?.target?.files[0]) {
                      setForm({ ...form, slip_file: e.target.files[0] })
                    }
                  }} />
                </div>
                <div className="ms-2" style={{ width: 40 }}>
                  {!!form.slip_file &&
                    <img src={URL.createObjectURL(form.slip_file)} className="rounded-sm" style={{ width: 40, height: 40 }} onClick={() => {
                      setIsLightboxOpen(true)
                    }} />}
                </div>
              </div>
              <Button disabled={submitting} className="btn btn-sm btn-warning" onClick={() => {
                if (dayQty < 1) {
                  toast.warn('กรุณาระบุวันเข้าพักให้ถูกต้อง')
                  return
                }

                if (!form.camp_type_id) {
                  toast.warn('กรุณาเลือกประเภทการเข้าพัก')
                  return
                }

                if (!form.contact_name) {
                  toast.warn('กรุณาระบุชื่อผู้ติดต่อ')
                  return
                }

                if (!form.contact_tel_1) {
                  toast.warn('กรุณาระบุเบอร์ติดต่อ อย่างน้อย 1 หมายเลข')
                  return
                }

                if (!form.total_price || form.total_price < 1) {
                  toast.warn('ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
                  return
                }

                if (payAmount > 0 && !form.slip_file) {
                  toast.warn('กรุณาแนบหลักฐานการชำระเงิน')
                  return
                }

                if (!form.guest_qty && form.guest_qty < 1) {
                  toast.warn('กรุณาระบุจำนวนผู้เข้าพัก')
                  return
                }

                if (!validateAddons()) {
                  toast.warn('กรุณาเลือกอุปกรณ์เสริมให้ครบถ้วน หรือลบรายการที่ไม่ต้องการออก')
                  return
                }

                setShowConfirmModal(true)
              }}>ยืนยันการจอง</Button>
            </div>
          </div>
          {isLightboxOpen && !!form.slip_file && (
            <Lightbox
              medium={URL.createObjectURL(form.slip_file)}
              large={URL.createObjectURL(form.slip_file)}
              alt={'Payment Slip'}
              onClose={() => {
                setIsLightboxOpen(false)
              }}
              hideDownload
              hideZoom
            />
          )}
        </div>}
      <Modal className="fade modal-close-out" show={showConfirmModal}>
        <Modal.Header>
          <Modal.Title>{'ยืนยันการจอง'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <p>
            <span>{'กรุณาตรวจสอบข้อมูลให้ถูกต้อง'} </span>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"secondary"} onClick={() => setShowConfirmModal(false)}>ปิด</Button>
          <Button disabled={submitting} variant="warning" onClick={() => {
            if (dayQty < 1) {
              toast.warn('กรุณาระบุวันเข้าพักให้ถูกต้อง')
              return
            }

            if (!form.contact_name) {
              toast.warn('กรุณาระบุชื่อผู้ติดต่อ')
              return
            }

            if (!form.contact_tel_1) {
              toast.warn('กรุณาระบุเบอร์ติดต่อ อย่างน้อย 1 หมายเลข')
              return
            }

            if ((!form.total_price || form.total_price < 1)) {
              toast.warn('ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
              return
            }

            if (payAmount > 0 && !form.slip_file) {
              toast.warn('กรุณาแนบหลักฐานการชำระเงิน')
              return
            }

            if (!form.guest_qty && form.guest_qty < 1) {
              toast.warn('กรุณาระบุจำนวนผู้เข้าพัก')
              return
            }

            if (!validateAddons()) {
              toast.warn('กรุณาเลือกอุปกรณ์เสริมให้ครบถ้วน หรือลบรายการที่ไม่ต้องการออก')
              return
            }

            if (!checkGuestQtyWithStartDateEndDate(startDate, endDate, form.guest_qty, form.guest_child_qty)) {
              toast.warn('จำนวนผู้เข้าพักเกินจำนวนที่สามารถจองได้')
              return
            }

            setSubmitting(true)

            const rawForm = {
              ...form,
              day_qty: dayQty,
              guest_child_qty: form.guest_child_qty || 0,
              guest_baby_qty: form.guest_baby_qty || 0,
              tent_qty: form.tent_qty || 0,
              is_walkin: 1,
              discount_code: couponCode,
              total_discount: discountPrice || 0,
              start_date: moment(startDate).format('YYYY-MM-DD'),
              end_date: moment(endDate).subtract(1, 'days').format('YYYY-MM-DD'),
            }

            const formData = new FormData();
            for (var key in rawForm) {
              if (key !== 'slip_file') {
                formData.append(key, rawForm[key]);
              }
            }

            if (payAmount > 0) {
              formData.append("file", rawForm.slip_file);
            }

            for (let i = 0; i < dayQty; i++) {
              const d = moment(startDate).add(i, 'days').format('YYYY-MM-DD')
              const bItem = {
                date: d,
                is_walkin: true,
                guest_qty: Number(form.guest_qty || 0) + Number(form.guest_child_qty || 0)
              }

              formData.append(`booking_items[${i}]`, JSON.stringify(bItem));
            }

            if (addons && addons.length) {
              addons.map((addonObj, i) => {
                formData.append(`booking_addons[${i}]`, JSON.stringify(addonObj));
              })
            }

            api.request(`bookings/bookingformweb`, (res, success) => {
              setSubmitting(false)
              setShowConfirmModal(false)
              if (!success) {
                return
              } else {
                toast.success('ทำการจองเรียบร้อยแล้ว')
                navigate(`/once/status/${res.id}/${res.booking_no}`)
              }
            }, formData, 'POST')
          }}>
            ยืนยันการจอง
          </Button>
        </Modal.Footer>
      </Modal>
      <OnceFooter />
    </>
  );
}
